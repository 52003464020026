import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    color: #1c1c1c;
    font-family: 'Libre Franklin', sans-serif;
    background-color: #FCFCFC;
    font-size: 14px;

    & * {
      box-sizing: border-box;
    }

    h1 {
      font-weight: 500;
      letter-spacing: .6px;
    }

    a {
      letter-spacing: .6px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export default GlobalStyle;
