import axios from 'axios';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import Layout from './Layout';
import RootStore from './stores/RootStore';

moment.tz.setDefault('Atlantic/Reykjavik');
moment.locale('en');

/**
 * FIREBASE SETUP
 */
if (!firebase.apps.length) {
  // Initialize Firebase
  const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY || '',
    authDomain: `${process.env.FIREBASE_PROJECT_ID || ''}.firebaseapp.com`,
    databaseURL: `https://${process.env.FIREBASE_PROJECT_ID ||
      ''}.firebaseio.com`,
    storageBucket: `${process.env.FIREBASE_PROJECT_ID || ''}.appspot.com`,
    projectId: process.env.FIREBASE_PROJECT_ID || '',
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || '',
  };
  firebase.initializeApp(firebaseConfig);
}

function getIdToken(refreshToken: boolean) {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    throw new Error('No user.');
  }

  return currentUser.getIdToken(refreshToken)
    .then((token: string) => token)
    .catch((error: any) => Promise.reject(error));
}

function getUser() {
  // TODO: set IsLoading = true

  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
      if (user) {
        resolve(user);
      } else {
        window.location.href = '/login';

        reject(Error('It broke'));
      }
    });
  });
}

/**
 * AXIOS INTERCEPTORS
 */
axios.interceptors.request.use(
  (response) =>
    getUser().then(() =>
      getIdToken(false)
        .then((token) => {
          response.headers.Authorization = `Bearer ${token}`;
          return response;
        })
        .catch((error) => {
          window.location.href = '/login';

          return Promise.reject(error);
        })),
  (error) => Promise.reject(error),
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // TODO: set IsLoading = false
    return response;
  },
  (error) => {
    // TODO: set IsLoading = false
    return Promise.reject(error);
  },
);

ReactDOM.render(<RootStore><Layout /></RootStore>, document.getElementById('root'));
