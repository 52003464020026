import React, { createContext, FunctionComponent } from 'react';
import { BookingStoreNS, useBookingStore } from './useBookingStore';
import { SearchCriteriaStoreNS, useSearchCriteriaStore } from './useSearchCriteriaStore';
import { UIStoreNS, useUIStore } from './useUIStore';

type Props = {
  children: JSX.Element,
};

type Stores = {
  UIStore: UIStoreNS.Store,
  SearchCriteriaStore: SearchCriteriaStoreNS.Store,
  BookingStore: BookingStoreNS.Store,
};

export const RootStoreContext = createContext({} as Stores);

const RootStore: FunctionComponent<Props> = ({ children }) => {
  const stores = {
    UIStore: useUIStore(),
    SearchCriteriaStore: useSearchCriteriaStore(),
    BookingStore: useBookingStore(),
  };

  return (
    <RootStoreContext.Provider value={stores}>
      {children}
    </RootStoreContext.Provider>
  );
};

export default RootStore;
