import React, { FC, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RootStoreContext } from '../stores/RootStore';

const Style = styled.div`
  position: fixed;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  animation: modalOpacityFadeIn 111ms cubic-bezier(0.14, 1.24, 1, 1) forwards;
  z-index: 999;

  @keyframes modalOpacityFadeIn {
    from {opacity: 0; will-change: opacity;}
    to {opacity: 1; will-change: opacity;}
  }

  > div {
    border-radius: 3px;
    background-color: #FFF;
  }
`;

type Props = {
  children: React.ReactNode,
};

const Modal: FC<Props> = ({ children }) => {
  const { UIStore: { reducer: [{ modalConfig }], closeModal } } = useContext(RootStoreContext);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (modalConfig.type !== 'None') {
      document.body.style.overflow = 'hidden';
    }

    return () => { document.body.style.overflow = 'auto'; };
  }, [modalConfig.type]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleEscClick);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleEscClick);
    };
  });

  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target) && !e.target!.id.includes('react-select')) {
      closeModal();
    }
  };

  const handleEscClick = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  if (modalConfig.type === 'None') {
    return null;
  }

  return (
    <Style>
      <div ref={ref}>
        {children}
      </div>
    </Style>
  );
};

export default Modal;
