import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { RootStoreContext } from '../stores/RootStore';
import { UIStoreNS } from '../stores/useUIStore';
import theme from '../styles/theme';

type MsgProps = {
  type: 'Success' | 'Error',
};

const Style = styled.div`
  position: fixed;
  top: 32px;
  right: 0px;
  height: 100%;
  pointer-events: none; /* So the container doesn't prevent clicks beneath it */
  z-index: 999999999;
`;

const Message = styled.div<MsgProps>`
  display: flex;
  min-width: 280px;
  min-height: 80px;
  margin-bottom: 16px;
  background-color: #fbfbfb;
  animation-name: showMessage;
  animation-duration: .3s;
  z-index: 999999999;

  ${(msgProps) => msgProps.type === 'Success' && css`
    box-shadow: 0px 3px 4px rgba(159, 223, 113, 0.2);
  `}
  ${(msgProps) => msgProps.type === 'Error' && css`
    box-shadow: 0px 3px 4px rgba(239, 83, 80, 0.2);
  `}

  @keyframes showMessage {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      width: 64px;
      border: 1px solid #F2F2F2;
      i {
        font-size: 36px;

        ${(msgProps) => msgProps.type === 'Success' && css`
          color: ${theme.colors.success};
        `}
        ${(msgProps) => msgProps.type === 'Error' && css`
          color: ${theme.colors.danger};
        `}
      }
    }
    &:last-child {
      flex-direction: column;
      max-width: 216px;
      padding: 8px 8px 8px 16px;

      * {
        width: 100%;
        padding: 0px;
        margin: 0px;
      }
      b {
        margin-bottom: 3px;
      }
      p {
        font-size: 12px;
      }
    }
  }
`;

const FlashMessageConductor = () => {
  const { UIStore: { reducer: [{ flashMessages }, uiStoreDispatch] } } = useContext(RootStoreContext);
  const [idsToBeRemoved, setIdsToBeRemoved] = useState<string[]>([]);

  useEffect(() => {
    // Get all new messages
    const newMsgs = flashMessages.filter((fm) => !idsToBeRemoved.includes(fm._id!));
    // Mark as to be removed
    setIdsToBeRemoved(newMsgs.map((nm) => nm._id!));
    // Set timeout for them
    newMsgs.forEach(((nm) => setTimeout(() => {
      uiStoreDispatch({
        type: UIStoreNS.ActionType.RemoveFlashMessage,
        msgId: nm._id!,
      });
    }, nm.timeout)));
  }, [flashMessages]);

  return (
    <Style>
      {
        flashMessages.map((fm) => <Message
          key={fm._id}
          type={fm.type}
        >
          <div><i className="material-icons">error</i></div>
          <div>
            <b>{fm.title}</b>
            <p>{fm.text}</p>
          </div>
        </Message>)
      }
    </Style>
  );
};

export default FlashMessageConductor;
