import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios, { CancelTokenSource } from 'axios';
import Fuse from 'fuse.js';
import moment from 'moment-timezone';
import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import Select from 'react-select';
import styled from 'styled-components';
import { getResults, RestaurantResult } from '../api/dashboard.api';
import { getFavoriteRestaurants, removeFavoriteRestaurant, setFavoriteRestaurant } from '../api/favorite.api';
import { FilterItemType, getFiltersInUse } from '../api/filter.api';
import ActivityOverview from '../components/ActivityOverview';
import ReactSelectStyled from '../components/common/ReactSelect.styled';
import DateSelector from '../components/DateSelector';
import GuestSelector from '../components/GuestSelector';
import Header from '../components/Header';
import RestaurantItem from '../components/RestaurantItem';
import RestaurantItemLoading from '../components/RestaurantItem/RestaurantItemLoading';
import SearchInput from '../components/SearchInput';
import TimeSelector from '../components/TimeSelector';
import ToggleSwitch from '../components/ToggleSwitch';
import { FilterType } from '../enum/FilterType';
import { SortByType } from '../enum/SortByType';
import useDebounce from '../hooks/useDebounce';
import { RootStoreContext } from '../stores/RootStore';
import { SearchCriteriaStoreNS } from '../stores/useSearchCriteriaStore';
import { UIStoreNS } from '../stores/useUIStore';

const Style = styled.div`
  position: relative;
  display: flex;
  overflow: auto;

  background-color: #FFF;

  .dashboard-left-side, .dashboard-right-side {
    height: 100vh;
  }

  .dashboard-left-side {
    position: relative;
    flex-basis: 80%;

    .dashboard-toggle-favorites {
      display: flex;
      justify-content: flex-end;
      padding-right: 16px;

      .switch {
        transform: scale(.5);
      }
    }
  }

  .dashboard-right-side {
    position: sticky;
    top: 0px;
    overflow: auto;

    display: flex;
    height: 100vh;
    flex-direction: column-reverse;
    justify-content: space-between;
    flex-basis: 30%;
    margin-left: 40px;
    padding: 32px;

    background-color: #f9f9f9;
    box-shadow: 17px 0 18px 13px rgba(0,0,0,.2);

    .dashboard-label {
      color: #999;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: .6px;
      font-weight: 600;

      &.dashboard-label-activity {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }

    .dashboard-filter-link {
        color: #666;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: .6px;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }

    .activity-overview {
      margin: 16px 0px 0px 0px;
    }

    .dashboard-filter-container {
      /* Filters title and Clear button */
      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      ul {
        padding: 0px;
        margin: 0px;
        list-style-type: none;

        li {
          &:not(:last-child) {
            margin-bottom: 32px;
          }

          label {
            display: block;
            color: #384360;
            font-weight: 600;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
`;

const SearchCriteriaStyled = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  padding: 16px;

  background-color: #FFF;
  z-index: 1;

  > div {
    flex: 1 1 auto;
    &:not(:last-child) {
      padding-right: 16px;
    }
  }
`;

const ListStyled = styled.div`
  margin-top: 40px;
  z-index: 0;

  .dashboard-restaurant-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

type Filters = {
  occasions: FilterItemType[],
  categories: FilterItemType[],
  cities: FilterItemType[][],
  priceCategories: FilterItemType[],
};

type OptionType = {
  label: string,
  value: number,
};

const defaultSortByObj = { label: 'Default', value: SortByType.None };

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'restaurantName',
  ],
};

// Global const to determine whether restaurants are being fetched for the first time
let initRestaurantsFetch = true;

const Dashboard: FC<RouteComponentProps> = ({ history }) => {
  const [selectedSortBy, setSelectedSortBy] = useState<{ label: string, value: SortByType }>(defaultSortByObj);
  const [selectedPrice, setSelectedPrice] = useState<OptionType[]>([]);
  const [selectedOccasions, setSelectedOccasions] = useState<OptionType[]>([]);
  const [selectedCuisines, setSelectedCuisines] = useState<OptionType[]>([]);
  const [selectedCities, setSelectedCities] = useState<Array<{ value: string, label: string }>>([]);
  const [allRestaurants, setAllRestaurants] = useState<RestaurantResult[]>([]);
  const [loadingRestaurants, setLoadingRestaurants] = useState(true);
  const [searchRestaurants, setSearchRestaurants] = useState<RestaurantResult[]>([]);
  const [allFilters, setAllFilters] = useState<Filters | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce<string>(searchValue, 300);
  const [isSearching, setIsSearching] = useState(false);
  const [favoriteRestIds, setFavoriteRestIds] = useState<number[]>([]);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState<boolean>(false);

  const { SearchCriteriaStore: [{ guests, dateTime }, bookingStoreDispatch],
    UIStore: { reducer: [, uiStoreDispatch] },
    BookingStore: { reducer: [{ lastBookingTimestamp }] } } = useContext(RootStoreContext);

  // Fetch restaurants from the API
  const fetchRestaurants = (cancelToken: CancelTokenSource) => {
    getResults(dateTime.format(moment.defaultFormatUtc), guests, selectedSortBy.value, formatFiltersForApi(), cancelToken)
      .then((res) => {
        setAllRestaurants(res.data);
        setLoadingRestaurants(false);
      })
      .catch((error) => {
        setLoadingRestaurants(false);
        if (!axios.isCancel(error)) {
          uiStoreDispatch({
            type: UIStoreNS.ActionType.AddFlashMessage,
            payload: { type: 'Error', title: 'Error', text: 'Could not fetch restaurants, please try again.', timeout: 3000 },
          });
        }
      });
  };

  // Connect fuzzy search
  const fuse = new Fuse(allRestaurants, fuseOptions);

  // Debounced restaurant fetch
  const fetchDebounced = AwesomeDebouncePromise((cancelToken: CancelTokenSource) => {
    getResults(dateTime.format(moment.defaultFormatUtc), guests, selectedSortBy.value, formatFiltersForApi(), cancelToken)
      .then((res) => {
        setAllRestaurants(res.data);
        setLoadingRestaurants(false);
      })
      .catch((error) => {
        setLoadingRestaurants(false);
        if (!axios.isCancel(error)) {
          uiStoreDispatch({
            type: UIStoreNS.ActionType.AddFlashMessage,
            payload: { type: 'Error', title: 'Error', text: 'Could not fetch restaurants, please try again.', timeout: 3000 },
          });
        }
      });
  }, 800);

  // Formats filters to the format API wants them
  const formatFiltersForApi = () => {
    if (!allFilters) {
      return '';
    }

    const flatCities = allFilters!.cities.reduce(
      (arr, elem) => [...arr, ...elem], [],
    );

    const obj: Array<{ id: number, type: FilterType }> = [
      ...selectedPrice.map((sp) => ({ id: sp.value, type: FilterType.PricingCategory })),
      ...selectedOccasions.map((so) => ({ id: so.value, type: FilterType.RestaurantCategory })),
      ...selectedCuisines.map((sc) => ({ id: sc.value, type: FilterType.RestaurantCategory })),
      ...flatCities.filter((fc) => selectedCities.find((sc) => sc.value === fc.name)),
    ];

    return obj.map((x) => `${x.id};${x.type}`).join(',');
  };

  /*
  * FETCH ALL FILTERS ON INITIAL LOAD
  */
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getFiltersInUse(cancelToken)
      .then((res) => {
        setAllFilters({
          occasions: res.data.occasions,
          categories: res.data.categories,
          cities: res.data.cities,
          priceCategories: [
            { id: 1, name: '$', type: FilterType.PricingCategory },
            { id: 2, name: '$$', type: FilterType.PricingCategory },
            { id: 3, name: '$$$', type: FilterType.PricingCategory },
            { id: 4, name: '$$$$', type: FilterType.PricingCategory }],
        });
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          uiStoreDispatch({
            type: UIStoreNS.ActionType.AddFlashMessage,
            payload: { type: 'Error', title: 'Error', text: 'Error fetching filters.', timeout: 5000 },
          });
        }
      });

    return () => cancelToken.cancel();
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getFavoriteRestaurants(cancelToken)
      .then((res) => {
        setFavoriteRestIds(res.data);
      })
      .catch((e) => {
        if (!axios.isCancel(e)) {
          uiStoreDispatch({
            type: UIStoreNS.ActionType.AddFlashMessage,
            payload: { type: 'Error', title: 'Error', text: 'Error fetching favorite restaurants.', timeout: 5000 },
          });
        }
      });
  }, []);

  /*
  * REFETCH RESTAURANTS FROM API WHEN FILTERS OR SEARCH CRITERIA (DATE, GUESTS) CHANGE
  */
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (initRestaurantsFetch) {
      fetchRestaurants(cancelToken);
      initRestaurantsFetch = false;
    } else {
      // Start loaders and nuke current restaurants state so that the list order in the DOM isn't shifting when new ones arrive
      setAllRestaurants([]);
      setSearchRestaurants([]);
      setLoadingRestaurants(true);

      // Fetch restaruants
      fetchDebounced(cancelToken);
    }

    return () => cancelToken.cancel();

  }, [lastBookingTimestamp, selectedSortBy, selectedPrice, selectedOccasions, selectedCuisines, selectedCities, dateTime.format(moment.defaultFormatUtc), guests]);

  /*
  * MAINTAIN SEARCHED LIST WHEN ALLRESTAURANTS CHANGE
  * SEARCH AGAIN WHEN SEARCHVALUE CHANGES
  */
  useEffect(() => {
    if (!searchValue) {
      setSearchRestaurants(allRestaurants);
    } else {
      const searchResult = fuse.search(debouncedSearchValue);
      setSearchRestaurants(searchResult);
    }
    // Stop loaders
    setIsSearching(false);

  }, [allRestaurants, debouncedSearchValue]);

  /*
  * DISPLAY LOADERS EACH TIME SEARCH VALUE CHANGES AND HAS VALUE
  */
  useEffect(() => {
    if (searchValue) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }

  }, [searchValue]);

  return (
    <Style>
      <div className="dashboard-left-side">
        <Header />
        <SearchCriteriaStyled>
          <div>
            <DateSelector
              dateTime={dateTime}
              onChange={(value) => bookingStoreDispatch({ type: SearchCriteriaStoreNS.ActionType.Date, payload: value })}
            />
          </div>
          <div>
            <TimeSelector
              dateTime={dateTime}
              onChange={(value) => bookingStoreDispatch({ type: SearchCriteriaStoreNS.ActionType.Time, payload: value })}
            />
          </div>
          <div>
            <GuestSelector
              guests={guests}
              onChange={(value) => bookingStoreDispatch({ type: SearchCriteriaStoreNS.ActionType.Guests, payload: value })}
            />
          </div>
          <div>
            <SearchInput
              placeholder="Search for a restaurant..."
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
            />
          </div>
        </SearchCriteriaStyled>
        <div className="dashboard-toggle-favorites">
          <ToggleSwitch
            isEnabled={showFavoritesOnly}
            onClick={() => setShowFavoritesOnly(!showFavoritesOnly)}
            title="Favorites only"
          />
        </div>
        <ListStyled>
          {
            !loadingRestaurants && !isSearching ? (
              <div className="dashboard-restaurant-grid">
                {searchRestaurants
                  .filter((rest) => showFavoritesOnly ? favoriteRestIds.includes(rest.restaurantId) : true)
                  .map((data) => (
                    <RestaurantItem
                      key={data.restaurantId}
                      data={data}
                      guests={guests}
                      dateTime={dateTime}
                      isFavorite={favoriteRestIds.includes(data.restaurantId)}
                      onFavorited={() => {
                        const isAdding = !favoriteRestIds.includes(data.restaurantId);

                        if (isAdding) {
                          // To show instantly in UI that restaurant has been favorited/removed
                          setFavoriteRestIds([...favoriteRestIds, data.restaurantId]);

                          setFavoriteRestaurant(data.restaurantId)
                            .then(() => {
                              // Do nothing
                            }).catch((e) => {
                              // Remove favorite if couldn't add it
                              setFavoriteRestIds(favoriteRestIds.filter((id) => id !== data.restaurantId));

                              if (!axios.isCancel(e)) {
                                uiStoreDispatch({
                                  type: UIStoreNS.ActionType.AddFlashMessage,
                                  payload: { type: 'Error', title: 'Error', text: 'Could not save favorite. Please try again.', timeout: 5000 },
                                });
                              }
                            });
                        } else {
                          // Remove favorites
                          setFavoriteRestIds(favoriteRestIds.filter((id) => id !== data.restaurantId));

                          removeFavoriteRestaurant(data.restaurantId)
                            .then(() => {
                              // Do nothing
                            }).catch((e) => {
                              // Re-insert favorites if couldn't delete it
                              setFavoriteRestIds([...favoriteRestIds, data.restaurantId]);

                              if (!axios.isCancel(e)) {
                                uiStoreDispatch({
                                  type: UIStoreNS.ActionType.AddFlashMessage,
                                  payload: { type: 'Error', title: 'Error', text: 'Could not save favorite. Please try again.', timeout: 5000 },
                                });
                              }
                            });
                        }
                      }}
                    />
                  ))}
              </div>) : (
                <>
                  <RestaurantItemLoading />
                  <RestaurantItemLoading />
                </>
              )
          }
        </ListStyled>
      </div>
      <div className="dashboard-right-side">
        <div>
          <b className="dashboard-label dashboard-label-activity">
            <div>Activity</div>
            <a
              onClick={() => history.push('/bookings')}
              className="dashboard-filter-link">See all</a>
          </b>
          <ActivityOverview />
        </div>
        <div className="dashboard-filter-container">
          <div>
            <b className="dashboard-label">Filters</b>
            <a
              className="dashboard-filter-link"
              onClick={() => {
                setSelectedSortBy(defaultSortByObj);
                setSelectedPrice([]);
                setSelectedOccasions([]);
                setSelectedCuisines([]);
                setSelectedCities([]);
              }}
            >
              Clear
            </a>
          </div>
          <ul>
            <li>
              <label>Sort by</label>
              <ReactSelectStyled>
                <Select
                  isLoading={!allFilters}
                  options={[
                    defaultSortByObj,
                    { label: 'Popularity', value: SortByType.Popularity },
                  ]}
                  value={selectedSortBy}
                  onChange={(val: any) => setSelectedSortBy(val)}
                /></ReactSelectStyled>

            </li>
            <li>
              <label>Price</label>
              <ReactSelectStyled>
                <Select
                  isLoading={!allFilters}
                  options={allFilters ? allFilters.priceCategories.map((c) => ({ label: c.name, value: c.id })) : []}
                  value={selectedPrice}
                  onChange={(val: any) => setSelectedPrice(val || [])}
                  isMulti
                /></ReactSelectStyled>

            </li>
            <li>
              <label>Occasion</label>
              <ReactSelectStyled>
                <Select
                  isLoading={!allFilters}
                  options={allFilters ? allFilters.occasions.map((c) => ({ label: c.name, value: c.id })) : []}
                  value={selectedOccasions}
                  onChange={(val: any) => setSelectedOccasions(val || [])}
                  isMulti
                />
              </ReactSelectStyled>
            </li>
            <li>
              <label>Cuisines</label>
              <ReactSelectStyled>
                <Select
                  isLoading={!allFilters}
                  options={allFilters ? allFilters.categories.map((c) => ({ label: c.name, value: c.id })) : []}
                  value={selectedCuisines}
                  onChange={(val: any) => setSelectedCuisines(val || [])}
                  isMulti
                />
              </ReactSelectStyled>
            </li>
            <li>
              <label>Neighbourhood</label>
              <ReactSelectStyled>
                <Select
                  isLoading={!allFilters}
                  options={allFilters ? allFilters.cities.map((c) => ({ label: c[0].name, value: c[0].name })) : []}
                  value={selectedCities}
                  onChange={(val: any) => setSelectedCities(val || [])}
                  isMulti
                />
              </ReactSelectStyled>
            </li>
          </ul>
        </div>
      </div>
    </Style>
  );
};

export default Dashboard;
