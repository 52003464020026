import React from 'react';
import { useReducer } from 'react';
import uuidv1 from 'uuid/v1';

export namespace UIStoreNS {
  export type State = {
    modalConfig: ModalConfig,
    flashMessages: FlashMessageItem[],
  };

  export type Store = { reducer: [State, React.Dispatch<Action>], closeModal: () => void };

  type ModalConfig =
    { type: 'None' } |
    { type: 'CreateBooking', restaurantId: number, dateTime: string, guests: number };

  export type FlashMessageItem = { type: 'Success', title: string, text: string, timeout?: number, _id?: string } |
  { type: 'Error', title: string, text: string, timeout?: number, _id?: string };

  export enum ActionType {
    Modal,
    AddFlashMessage,
    RemoveFlashMessage,
  }

  export type Action = { type: ActionType.Modal, payload: ModalConfig } |
  { type: ActionType.AddFlashMessage, payload: FlashMessageItem } |
  { type: ActionType.RemoveFlashMessage, msgId: string };
}

export const useUIStore = () => {
  const initState: UIStoreNS.State = {
    modalConfig: { type: 'None' },
    flashMessages: [],
  };

  const storeReducer = (storeState: UIStoreNS.State, action: UIStoreNS.Action): UIStoreNS.State => {
    switch (action.type) {
      case UIStoreNS.ActionType.Modal:
        return { ...storeState, modalConfig: action.payload };
      case UIStoreNS.ActionType.AddFlashMessage:
        return {
          ...storeState,
          flashMessages: [
            ...storeState.flashMessages,
            {
              ...action.payload,
              _id: uuidv1(),
              timeout: action.payload.timeout || 3000,
            },
          ],
        };
      case UIStoreNS.ActionType.RemoveFlashMessage:
        return {
          ...storeState,
          flashMessages: storeState.flashMessages.filter((fm) => fm._id !== action.msgId),
        };
      default:
        throw new Error('Could not find state type in UI store');
    }
  };

  const reducer = useReducer(storeReducer, initState);
  const [, dispatch] = reducer;
  const closeModal = () => dispatch({ type: UIStoreNS.ActionType.Modal, payload: { type: 'None' } });

  return { reducer, closeModal };
};
