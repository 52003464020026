import moment, { Moment } from 'moment-timezone';
import React, { FC, useMemo } from 'react';
import Select from 'react-select';
import ReactSelectStyled from './common/ReactSelect.styled';

type Props = {
  dateTime: Moment,
  onChange: (value: string) => void;
};

const TimeSelector: FC<Props> = ({ dateTime, onChange }) => {
  const timeOptions = useMemo(() => {
    const tempDate = moment().set({ hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
    const options: Array<{ label: string, value: string }> = [];
    while (tempDate <= moment().set({ hours: 23, minutes: 45, seconds: 0, milliseconds: 0 })) {
      options.push({ label: tempDate.format('HH:mm'), value: tempDate.format('HH:mm') });
      tempDate.add(15, 'minutes');
    }
    return options;
  }, []);

  return (
    <ReactSelectStyled>
      <Select
        value={{ label: dateTime.format('HH:mm'), value: dateTime.format('HH:mm') }}
        onChange={(optionVal: any) => onChange(optionVal.value)}
        options={timeOptions}
      />
    </ReactSelectStyled>
  );
};

export default TimeSelector;
