import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../styles/theme';

const StyledButton = styled.button<Props>`
  position: relative;
  height: 54px;
  padding: 0px 16px;

  border: 0px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: .6px;
  color: white;
  cursor: pointer;
  overflow-y: hidden;

  &:hover {
    transition: all .111s ease-in;
  }

  ${({ shouldFillContainer }) => shouldFillContainer && css`
    width: 100%;
  `};

  ${({ isLoading }) =>
    isLoading && css`
      > div > .button-spinner-container {
        opacity: 1;
        transition: opacity 0.2s ease-in;
      }
  `};

  ${({ appearance }) => appearance === 'Primary' && css`
    background-color: ${theme.colors.brand};
  `};

  ${({ appearance }) => appearance === 'Success' && css`
    background-color: ${theme.colors.success};
  `};

  ${({ appearance }) => appearance === 'Secondary' && css`
    background-color: #FFF;
    border: 1px solid #e8e8e8;
    color: #1c1c1c;
  `};

  ${({ disabled }) => disabled && css`
    opacity: 0.3;
    &:hover {
      cursor: default;
    }
  `};
`;

const SpinnerStyled = styled.div`
  .button-spinner-container {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .button-spinner {
    animation: rotator 1.4s linear infinite;
    .path {
      stroke-dasharray: 187;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation: dash 1.4s ease-in-out infinite,
        colors 5.6s ease-in-out infinite;
    }
  }
  @keyframes colors {
    0% {
      stroke: #fff;
    }
    50% {
      stroke: #fff;
    }
    100% {
      stroke: #fff;
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
`;

type Appearance = 'Primary' | 'Success' | 'Secondary';

type Props = {
  disabled?: boolean,
  isLoading?: boolean,
  appearance?: Appearance,
  shouldFillContainer?: boolean,
  onClick: () => void,
  tabIndex?: number,
};

const Spinner = () => (
  <SpinnerStyled>
    <div className="button-spinner-container">
      <svg
        className="button-spinner"
        width="32px"
        height="32px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  </SpinnerStyled>
);

const Button: FC<Props> = ({ appearance = 'Primary', disabled = false, isLoading = false, shouldFillContainer = true, onClick, tabIndex = 0, children }) => {

  return (
    <StyledButton
      type="button"
      appearance={appearance}
      disabled={disabled}
      isLoading={isLoading}
      shouldFillContainer={shouldFillContainer}
      onClick={!disabled ? onClick : () => { }}
      tabIndex={tabIndex}
    >
      {!isLoading ? children : <Spinner />}
    </StyledButton>
  );
};

export default Button;
