import React, { FC, useMemo } from 'react';
import Select from 'react-select';
import ReactSelectStyled from './common/ReactSelect.styled';

type Props = {
  guests: number,
  onChange: (value: number) => void,
};

const GuestSelector: FC<Props> = ({ guests, onChange }) => {
  const guestOptions = useMemo(() => Array(16).fill(0).map((_, index) => ({ label: `${index + 1} pers.`, value: index + 1 })), []);

  return (
    <ReactSelectStyled>
      <Select
        options={guestOptions}
        value={{ label: `${guests} pers.`, value: guests }}
        onChange={(optionValue: any) => onChange(optionValue.value)}
      />
    </ReactSelectStyled>
  );
};

export default GuestSelector;
