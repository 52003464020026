import styled from 'styled-components';

const InputStyled = styled.input`
  width: 100%;
  min-height: 54px;
  padding: 0 8px;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  outline: 0 !important;
  font-size: 14px;

  &:focus, &:active {
    border-color: #2684FF;
    box-shadow: 0 0 0 1px #2684FF;
  }
  &:hover {
    border-color: hsl(0,0%,70%);
  }
`;

export default InputStyled;
