import moment from 'moment-timezone';
import React, { FC, useContext } from 'react';
import { SuggestionItem } from '../api/dashboard.api';
import { RootStoreContext } from '../stores/RootStore';
import { UIStoreNS } from '../stores/useUIStore';
import Button from './common/Button';

type Props = {
  data: SuggestionItem,
  restaurantId: number,
  guests: number,
  isCurrentlySelected: boolean,
};

const SuggestionItem: FC<Props> = ({ data, restaurantId, guests, isCurrentlySelected }) => {
  const { UIStore: { reducer: [, uiStoreDispatch] } } = useContext(RootStoreContext);
  return (
    <Button
      onClick={() => uiStoreDispatch({
        type: UIStoreNS.ActionType.Modal, payload: {
          type: 'CreateBooking',
          restaurantId,
          dateTime: data.date,
          guests,
        },
      })}
      tabIndex={-1}
      disabled={!data.isAvailable}
      appearance={isCurrentlySelected && data.isAvailable ? 'Success' : 'Primary'}
    >
      {moment(data.date).format('HH:mm')}
    </Button>
  );
};

export default SuggestionItem;
