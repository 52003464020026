import { AxiosResponse, CancelTokenSource } from 'axios';
import { FilterType } from '../enum/FilterType';
import API from './api';

export type FilterItemType = { id: number, name: string, type: FilterType };

const api = new API();

export function getFiltersInUse(cancelToken: CancelTokenSource): Promise<AxiosResponse<{
  occasions: FilterItemType[],
  categories: FilterItemType[],
  cities: FilterItemType[][],
}>> {
  return api._get('/filter/inuse', cancelToken);
}
