import React, { FC, useContext } from 'react';
import { RootStoreContext } from '../stores/RootStore';
import BookingCreate from './BookingCreate';

const ModalConductor: FC<{}> = () => {
  const { UIStore: { reducer: [{ modalConfig }] } } = useContext(RootStoreContext);

  switch (modalConfig.type) {
    case 'None':
      return null;
    case 'CreateBooking':
      return <BookingCreate restaurantId={modalConfig.restaurantId} dateTime={modalConfig.dateTime} guests={modalConfig.guests} />;
    default:
      return null;
  }
};

export default ModalConductor;
