import moment, { Moment } from 'moment-timezone';
import React, { FC, useMemo } from 'react';
import Select from 'react-select';
import { generateDaysSelectOptions } from '../utils/DateUtils';
import ReactSelectStyled from './common/ReactSelect.styled';

type Props = {
  dateTime: Moment,
  onChange: (value: string) => void,
};

const DateSelector: FC<Props> = ({ dateTime: date, onChange }) => {
  const dateOptions = useMemo(() => generateDaysSelectOptions(moment(), 180), []);

  return (
    <ReactSelectStyled>
      <Select
        options={dateOptions}
        value={{ label: date.format('MMMM D, YYYY'), value: date.format('YYYY-MM-DD') }}
        onChange={(optionVal: any) => onChange(optionVal.value)}
      />
    </ReactSelectStyled>
  );
};

export default DateSelector;
