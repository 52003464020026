import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAgentBookingsTodayCount } from '../api/dashboard.api';
import { RootStoreContext } from '../stores/RootStore';
import theme from '../styles/theme';

const Style = styled.div`
  display: flex;
  align-items: center;

  /* ICON */
  > div:first-child {
    display: flex;
    height: 52px;
    width: 52px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;

    background-color: ${theme.colors.brand};
    border-radius: 16px;

    > i {
      color: #FFF;
    }
  }

  /* DATA */
  > div:last-child {
    > div:first-child {
      font-size: 28px;
      color: #666;
    }
    > div:last-child {
      font-size: 12px;
      color: #999;
      font-weight: 400;
    }
  }
`;

const ActivityOverview = () => {
  const { BookingStore: { reducer: [{ lastBookingTimestamp }] } } = useContext(RootStoreContext);
  const [bookingsTodayCount, setBookingsTodayCount] = useState<number | null>(null);

  /**
   * FETCH BOOKINGS TODAY COUNT
   */
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getAgentBookingsTodayCount(cancelToken)
      .then((res) => setBookingsTodayCount(res.data))
      .catch(() => {
        // Silently fail this...?
      });

    return () => cancelToken.cancel();
  }, [lastBookingTimestamp]);

  return (
    <Style className="activity-overview">
      <div>
        <i className="material-icons">local_dining</i>
      </div>
      <div>
        <div>{bookingsTodayCount === null ? '...' : bookingsTodayCount}</div>
        <div>Bookings today</div>
      </div>
    </Style>
  );
};

export default ActivityOverview;
