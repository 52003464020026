import { Moment } from 'moment-timezone';

// Generate list of days from startDay
export const generateDaysSelectOptions = (startDate: Moment, howMany: number):
  Array<{
    label: string;
    value: string;
  }> => {
  let dayCounter = 0;
  return Array.from(Array(howMany), () => {
    const day = startDate.clone().add(dayCounter++, 'days');

    return {
      label: dayCounter <= 2 ? day.calendar(startDate, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
      }) : day.format('MMMM D, YYYY (ddd)'),
      value: day.format('YYYY-MM-DD'),
    };
  });
};

export const nextHour = (dateTime: Moment): Moment => dateTime.set({ hours: dateTime.get('hours') + 1, minutes: 0, seconds: 0, milliseconds: 0 });

export function getDefaultSearchDateAndTime(now: Moment) {
  // If current time is more than 20:00, we init to day after at 18:00
  if (now.hours() >= 20) {
    const dayAfter = now.clone().add(1, 'day');
    dayAfter.set({ hours: 18, minutes: 0, seconds: 0, milliseconds: 0 });
    return dayAfter;
  } else if (now.hours() < 8) {
    // If current time is before 08:00 AM, we init to 8 AM
    const time = now.clone();
    time.set({ hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
    return time;
  }

  // Init to current time + two hours
  const currDateAndTime = now.clone();
  currDateAndTime.add(2, 'hours');
  currDateAndTime.set({ minutes: 0, seconds: 0, milliseconds: 0 });

  return currDateAndTime;
}
