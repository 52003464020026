import React from 'react';
import { useReducer } from 'react';

export namespace BookingStoreNS {
  export type State = {
    lastBookingTimestamp: number | null,
  };

  export type Store = { reducer: [State, React.Dispatch<Action>] };

  export enum ActionType {
    LastBookingTimestamp,
  }

  export type Action = { type: ActionType.LastBookingTimestamp, payload: number };
}

export const useBookingStore = () => {
  const initState: BookingStoreNS.State = {
    lastBookingTimestamp: null,
  };

  const storeReducer = (storeState: BookingStoreNS.State, action: BookingStoreNS.Action): BookingStoreNS.State => {
    switch (action.type) {
      case BookingStoreNS.ActionType.LastBookingTimestamp:
        return { ...storeState, lastBookingTimestamp: action.payload };
      default:
        throw new Error('Could not find state type in booking store');
    }
  };

  const reducer = useReducer(storeReducer, initState);

  return { reducer };
};
