import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';

const Style = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .switch.switch--default > .switch-toggle {
     height: 32px;
     width: 52px;
     cursor: pointer !important;
     user-select: none !important;
     position: relative !important;
     display: inline-block;
  }
  .switch.switch--default > .switch-toggle.switch-toggle--on::before, .switch.switch--default > .switch-toggle.switch-toggle--on::after, .switch.switch--default > .switch-toggle.switch-toggle--off::before, .switch.switch--default > .switch-toggle.switch-toggle--off::after {
      content: '';
      left: 0;
      position: absolute !important;
  }
  .switch.switch--default > .switch-toggle.switch-toggle--on::before, .switch.switch--default > .switch-toggle.switch-toggle--off::before {
      height: inherit;
      width: inherit;
      border-radius: 16px;
      will-change: background;
      transition: background .4s .3s ease-out;
  }
  .switch.switch--default > .switch-toggle.switch-toggle--on::after, .switch.switch--default > .switch-toggle.switch-toggle--off::after {
      top: 2px;
      height: 28px;
      width: 28px;
      border-radius: 14px;
      background: #ffffff !important;
      will-change: transform;
      transition: transform .4s ease-out;
  }
  .switch.switch--default > .switch-toggle.switch-toggle--on::before {
      background: ${theme.colors.brand} !important;
  }
  .switch.switch--default > .switch-toggle.switch-toggle--on::after {
      transform: translateX(22px);
  }
  .switch.switch--default > .switch-toggle.switch-toggle--off::before {
      background: #999 !important;
  }
  .switch.switch--default > .switch-toggle.switch-toggle--off::after {
      transform: translateX(2px);
  }
  .switch.switch--graphite-small > .switch-toggle {
      height: 20px;
      width: 32.5px;
      cursor: pointer !important;
      user-select: none !important;
      position: relative !important;
      display: inline-block;
  }
  .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
      content: '';
      left: 0;
      position: absolute !important;
  }
  .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
      height: inherit;
      width: inherit;
      border-radius: 10px;
      will-change: background;
      transition: background .4s .3s ease-out;
  }
  .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
      top: 2px;
      height: 16px;
      width: 16px;
      border-radius: 8px;
      background: #ffffff !important;
      will-change: transform;
      transition: transform .4s ease-out;
  }
  .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before {
      background: gray !important;
  }
  .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after {
      transform: translateX(14.5px);
  }
  .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
      background: #cccccc !important;
  }
  .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
      transform: translateX(2px);
  }

  .switch-title {
    text-transform: uppercase;
    color: #999;
    font-weight: 500;
    transition: color 111ms ease-in-out 111ms;

    &.active {
      color: ${theme.colors.black};
    }
  }
`;

type Props = {
  isEnabled: boolean,
  onClick: () => void,
  title?: string,
};

const ToggleSwitch: FC<Props> = ({ isEnabled, onClick, title }) => {
  const [toggleClass, setToggleClass] = useState<'switch-toggle--off' | 'switch-toggle--on'>('switch-toggle--off');

  useEffect(() => {
    setToggleClass(isEnabled ? 'switch-toggle--on' : 'switch-toggle--off');
  }, [isEnabled]);

  return (
    <Style
      onClick={onClick}
    >
      <div
        className="switch switch--default d-flex">
        <div className={`switch-toggle ${toggleClass}`}></div>
      </div>
      {
        title && (
          <div className={`switch-title ${isEnabled ? 'active' : ''}`}>{title}</div>
        )
      }
    </Style>
  );
};

export default ToggleSwitch;
