import styled from 'styled-components';

const PhoneInputStyled = styled.div`
  width: 100%;
  background-color: hsl(0,0%,100%);

  &:focus-within {
    .react-phone-number-input__row
      .react-phone-number-input__country--native {
      &::after {
        content: '';
        position: absolute;
        top: 0px;
        right: -1px;
        height: 100%;
        width: 2px;
        background-color: #2684FF;
      }
    }
  }
  &:hover {
    .react-phone-number-input__row
      .react-phone-number-input__country--native,
    .react-phone-number-input__row
      input.react-phone-number-input__input {
      border-color: hsl(0,0%,70%);
    }
  }

  .react-phone-number-input__row {
    display: flex;
    height: 54px;

    .react-phone-number-input__country--native {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      padding: 0px 8px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid hsl(0,0%,80%);
      border-right: 0px;

      &:focus-within {
        border-color: #2684FF;
        box-shadow: 0 0 0 1px #2684FF;
      }

      .react-phone-number-input__icon {
        width: 1.24em;
        height: 0.93em;
        border: 1px solid rgba(0, 0, 0, 0.5);
        box-sizing: content-box;
      }
      .react-phone-number-input__country-select {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;
      }
      .react-phone-number-input__country-select-arrow {
        display: block;
        content: '';
        width: 0;
        height: 0;
        margin-bottom: 0.1em;
        margin-top: 0.3em;
        margin-left: 0.3em;
        border-width: 0.35em 0.2em 0 0.2em;
        border-style: solid;
        border-left-color: transparent;
        border-right-color: transparent;
        color: #B8BDC4;
        opacity: 0.7;
        transition: color 0.1s;
      }
    }

    input.react-phone-number-input__input {
      height: 100%;
      width: 100%;
      padding-left: 8px;

      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid hsl(0,0%,80%);
      border-left: 0px;

      &:focus {
        border-color: #2684FF;
        box-shadow: 0 0 0 1px #2684FF;
        outline: 0px;
      }
    }
  }


`;

export default PhoneInputStyled;
