import axios from 'axios';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import ReactTable, { RowRenderProps } from 'react-table';
import 'react-table/react-table.css';
import styled from 'styled-components';
import { getAgentBookings, IAgentBookingType } from '../api/booking.api';
import Header from '../components/Header';
import { RootStoreContext } from '../stores/RootStore';
import { UIStoreNS } from '../stores/useUIStore';

const Style = styled.div`
  > main {
    padding: 0px 16px;
  }
`;

const columns = [{
  Header: 'Employee',
  accessor: 'agentEmployeeName',
  filterable: true,
}, {
  Header: 'Customer',
  accessor: 'bookingName',
  filterable: true,
}, {
  Header: 'Restaurant',
  accessor: 'restaurantName',
  filterable: true,
}, {
  Header: 'Booking Date/Time',
  accessor: 'start',
  Cell: (props: RowRenderProps) => moment(props.row.start, moment.defaultFormatUtc).format('LL HH:mm'),
}, {
  Header: 'Status',
  accessor: 'statusName',
}, {
  Header: 'Created',
  accessor: 'created',
  Cell: (props: RowRenderProps) => moment(props.row.created, moment.defaultFormatUtc).format('LL HH:mm'),
}];

const Bookings = () => {
  const { UIStore: { reducer: [, uiStoreDispatch] } } = useContext(RootStoreContext);
  const [allBookings, setAllBookings] = useState<IAgentBookingType[] | null>(null);

  /**
   * FETCH ALL BOOKINGS
   */
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getAgentBookings(cancelToken)
      .then((res) => {
        setAllBookings(res.data);
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          uiStoreDispatch({
            type: UIStoreNS.ActionType.AddFlashMessage,
            payload: { type: 'Error', title: 'Error', text: 'Error fetching bookings.', timeout: 5000 },
          });
        }
      });

    return () => cancelToken.cancel();
  }, []);

  return (
    <Style>
      <Header />
      <main>
        {
          allBookings === null ? <div>Loading...</div> : (
            <ReactTable
              data={allBookings}
              columns={columns}
              defaultFilterMethod={(filter: any, row: any) => {
                const id = filter.pivotId || filter.id;
                if (id === 'id') {
                  return row[id] !== undefined
                    ? String(row[id]).startsWith(filter.value)
                    : true;
                }
                return row[id] !== undefined
                  ? String(row[id])
                    .toLowerCase()
                    .trim()
                    .replace(/\s/g, '')
                    .includes(filter.value
                      .toLowerCase()
                      .trim()
                      .replace(/\s/g, ''))
                  : true;
              }}
            />
          )
        }
      </main>
    </Style>
  );
};

export default Bookings;
