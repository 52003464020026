import React from 'react';
import styled from 'styled-components';
import Shimmer from '../Shimmer';

const slimBp: number = 375; // Breakpoint where restaurant goes horizontally aligned

const StyleLoading = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(126, 126, 126, 0.15);
  padding: 8px;
  border-radius: 3px;
  user-select: none;
  display: block;
  height: 224px;
  margin: 8px 16px 16px;
  padding: 8px;

  @media screen and (min-width: ${slimBp}px) {
    display: flex;
  }

  .ri-img {
    padding: 16px;
    display: flex;
    align-items: center;
    flex-basis: 20%;


    > div {
      width: 136px;
      height: 136px;
      margin: 0 auto;
      border-radius: 3px;
      background-color: green;
      border-radius: 50%;
    }
  }

  .ri-info-and-suggestions-container {
    width: 100%;
    margin-top: 16px;
    .ri-info {
      padding: 8px;

      .ri-name-and-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        > div:first-child {
          /* Restaurant name */
          position: relative;
          width: 100%;
          height: 18px;
          width: 80%;
          margin-right: 40px;
          border-radius: 3px;
        }
        > div:last-child {
          /* Price category */
          flex-shrink: 0;
          width: 20%;
          height: 18px;
          border-radius: 3px;
        }
      }

      .ri-city-and-category {
        margin-bottom: 8px;
        > div {
          height: 10px;
          width: 80px;
          border-radius: 3px;
          margin-bottom: 8px;
        }
        > div:first-child {
          width: 64px;
        }
      }
    } /* .ri-info ends */

    .ri-suggestions {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px;

      > div {
        height: 32px;
        width: 64px;
        max-width: 80px;
        flex-grow: 1;
        flex-basis: 0;
        background-color: green;
        border-radius: 3px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  } /* .ri-info-and-suggestions-container ends */
`;

const RestaurantItemLoading = () => (
  <StyleLoading>
    <div className="ri-img"><Shimmer /></div>

    <div className="ri-info-and-suggestions-container">
      <div className="ri-info">
        <div className="ri-name-and-price">
          <Shimmer />
          <Shimmer />
        </div>

        <div className="ri-city-and-category">
          <Shimmer />
          <Shimmer />
        </div>
      </div>
      <div className="ri-suggestions">
        {
          Array(8).fill(0).map((_, index) => (
            <Shimmer key={index} />
          ))
        }
      </div>
    </div>
  </StyleLoading>
);

export default RestaurantItemLoading;
