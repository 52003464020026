import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

const api = new API();

export interface IBookingType {
  start: string;
  guests: number;
  bookingName: string;
  bookingPhone: string;
  bookingEmail: string;
  bookingSpecialRequest: string;
  agentEmployeeId: number | null;
  restaurantId: number;
}

export interface IAgentBookingType {
  agentEmployeeId: number;
  agentEmployeeName: string;
  agentEmployeeAbbreviation: string;

  restaurantName: string;

  id: number;
  guests: number;
  start: string;
  end: string;
  status: number;
  statusName: string;

  bookingName: string;
  bookingPhone: string;
  bookingEmail: string;
  bookingSpecialRequest: string;
  bookingInternalNotes: string;

  created: string;
}

export const createBooking: (data: IBookingType) => Promise<AxiosResponse<{
  success: boolean,
}>> =
  (data) => {
    // Omitting cancel token since this is a POST
    return api._post('/booking', data);
  };

export const getAgentBookings: (cancelToken: CancelTokenSource) => Promise<AxiosResponse<IAgentBookingType[]>> =
  (cancelToken) => {
    return api._get('/booking/getagentbookings', cancelToken);
  };
