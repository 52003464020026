import { AxiosResponse, CancelTokenSource } from 'axios';
import { SortByType } from '../enum/SortByType';
import API from './api';

export type SuggestionItem = {
  isAvailable: boolean,
  date: string,
};

export type RestaurantResult = {
  restaurantId: number,
  restaurantName: string,
  city: { id: number, name: string },
  categories: Array<{ id: number, name: string }>,
  priceCategoryId: number,
  lat: number | null,
  lon: number | null,
  logoUrl: string | null,
  suggestions: SuggestionItem[],
};

const api = new API();

export const getResults = (dateTime: string, guests: number, sortBy: SortByType, filtersStr: string, cancelToken: CancelTokenSource): Promise<AxiosResponse<RestaurantResult[]>> => {
  let path = `/dashboard/results/${dateTime}/${guests}/${sortBy}`;
  path += filtersStr ? `/${filtersStr}` : '';
  return api._get(path, cancelToken);
};

export const getAgentBookingsTodayCount: (cancelToken: CancelTokenSource) => Promise<AxiosResponse<number>> =
  (cancelToken) => {
    return api._get('/dashboard/getagentbookingstodaycount', cancelToken);
  };
