import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Style = styled.div`
  ${(props: { priceCategoryId: number }) => props.priceCategoryId && css`
    span:nth-child(n+${props.priceCategoryId + 1}):nth-child(-n+4) {
      color: #DADADA;
    }
  `}
`;

type Props = {
  priceCategoryId: number,
};

const PriceIndicator: FC<Props> = ({ priceCategoryId }) => (
  <Style priceCategoryId={priceCategoryId}>
    <span>$</span><span>$</span><span>$</span><span>$</span>
  </Style>
);

export default PriceIndicator;
