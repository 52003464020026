import axios from 'axios';
import moment from 'moment-timezone';
import React, { FC, useContext, useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { createBooking, IBookingType } from '../api/booking.api';
import { AgentEmployee, createEmployee, getAllEmployees } from '../api/employee.api';
import { RootStoreContext } from '../stores/RootStore';
import { BookingStoreNS } from '../stores/useBookingStore';
import { UIStoreNS } from '../stores/useUIStore';
import { capitalizeFirstLetters } from '../utils/MiscUtils';
import Button from './common/Button';
import InputStyled from './common/Input.styled';
import PhoneInputStyled from './common/PhoneInput.styled';
import ReactSelectStyled from './common/ReactSelect.styled';
import TextareaStyled from './common/Textarea.styled';

const Style = styled.div`
  padding: 32px;

  > header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 32px;

    > div {
      h3 {
        margin: 0px;
      }
      sub {
        display: flex;
        padding: 8px 0px;
        font-size: 14px;

        > span {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 16px;

          > i {
            display: block;
            margin-right: 8px;
            font-size: 14px;
            color: #999;
          }
        }
      }
    }
  }

  > main {
    > * {
      margin-bottom: 8px;

      &:last-child {
        margin-top: 32px;
      }
    }

    .bc-flex-row {
      display: flex;

      > * {
        flex: 1;
        flex-basis: 25%;
      }
      > *:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
`;

interface IBookingTypeExt extends IBookingType {
  lastName: string;
}

type Props = {
  restaurantId: number,
  dateTime: string,
  guests: number,
};

const BookingCreate: FC<Props> = ({ restaurantId, dateTime, guests }) => {
  const { UIStore: { reducer: [, uiStoreDispatch], closeModal }, BookingStore: { reducer: [, bookingStoreDispatch] } } = useContext(RootStoreContext);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [agentEmployees, setAgentEmployees] = useState<AgentEmployee[] | null>(null);
  const [isCreatingEmployee, setIsCreatingEmployee] = useState(false);
  const [isCreatingBooking, setIsCreatingBooking] = useState<boolean>(false);
  const [booking, setBooking] = useState<IBookingTypeExt>({
    start: dateTime,
    guests,
    bookingName: '',
    lastName: '',
    bookingPhone: '',
    bookingEmail: '',
    bookingSpecialRequest: '',
    agentEmployeeId: null,
    restaurantId,
  });

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    getAllEmployees(cancelToken)
      .then((res) => {
        setAgentEmployees(res.data);
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          uiStoreDispatch({
            type: UIStoreNS.ActionType.AddFlashMessage,
            payload: { type: 'Error', title: 'Error', text: 'Could not fetch employees.', timeout: 10000 },
          });
        }
      });

    return () => cancelToken.cancel();
  }, []);

  useEffect(() => {
    if (!booking.agentEmployeeId) {
      setIsFormValid(false);
      return;
    } else if (!booking.bookingName || booking.bookingName.length < 3) {
      setIsFormValid(false);
      return;
    } else if (!isValidPhoneNumber(booking.bookingPhone)) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);

  }, [booking]);

  const book = () => {
    setIsCreatingBooking(true);
    createBooking({
      ...booking,
      bookingName: booking.bookingName + ' ' + booking.lastName,
    }).then((res) => {
      setIsCreatingBooking(false);
      if (res.data.success) {
        closeModal();
        uiStoreDispatch({
          type: UIStoreNS.ActionType.AddFlashMessage,
          payload: { type: 'Success', title: 'Booking created', text: 'Booking has successfully been created.', timeout: 5000 },
        });
        bookingStoreDispatch({
          type: BookingStoreNS.ActionType.LastBookingTimestamp,
          payload: new Date().getTime(),
        });
      } else {
        setIsCreatingBooking(false);
        uiStoreDispatch({
          type: UIStoreNS.ActionType.AddFlashMessage,
          payload: { type: 'Error', title: 'Error', text: 'Error creating booking.', timeout: 10000 },
        });
      }
    })
      .catch((e) => {
        setIsCreatingBooking(false);
        uiStoreDispatch({
          type: UIStoreNS.ActionType.AddFlashMessage,
          payload: { type: 'Error', title: 'Error', text: 'Error creating booking.', timeout: 10000 },
        });
      });
  };

  return (
    <Style>
      <header>
        <div>
          <sub>
            <span><i className="material-icons">calendar_today</i>{moment(dateTime, moment.defaultFormatUtc).format('LL')}</span>
            <span><i className="material-icons">access_time</i>{moment(dateTime, moment.defaultFormatUtc).format('HH:mm')}</span>
            <span><i className="material-icons">group</i>{guests} guests</span>
          </sub>
        </div>
        <a onClick={() => closeModal()} tabIndex={0}><i className="material-icons">close</i></a>
      </header>
      <main>
        <div className="bc-flex-row">
          <InputStyled
            placeholder="First name *"
            type="text"
            name="given-name"
            autoFocus
            value={booking.bookingName}
            onChange={(e) => setBooking({
              ...booking,
              bookingName: e.target.value,
            })}
          />
          <InputStyled
            placeholder="Last name"
            type="text"
            name="surname"
            value={booking.lastName}
            onChange={(e) => setBooking({
              ...booking,
              lastName: e.target.value,
            })}
          />
        </div>
        <div className="bc-flex-row">
          <PhoneInputStyled>
            <PhoneInput
              value={booking.bookingPhone}
              onChange={(phone: string) => setBooking({
                ...booking,
                bookingPhone: phone,
              })}
              country="IS"
              placeholder="Phone *"
            />
          </PhoneInputStyled>
          <InputStyled
            placeholder="Email"
            type="email"
            name="email"
            value={booking.bookingEmail}
            onChange={(e) => setBooking({
              ...booking,
              bookingEmail: e.target.value,
            })}
          />
        </div>
        <div>
          <TextareaStyled
            rows={6}
            placeholder="Special request"
            value={booking.bookingSpecialRequest}
            onChange={(e) => setBooking({
              ...booking,
              bookingSpecialRequest: e.target.value,
            })}
          />
        </div>
        <ReactSelectStyled>
          <CreatableSelect
            isLoading={agentEmployees === null || isCreatingEmployee}
            options={agentEmployees && agentEmployees.map((x) => ({ value: x.id, label: `${x.name} (${x.abbreviation})` })) || []}
            placeholder="Choose employee *"
            value={agentEmployees && booking.agentEmployeeId ? { value: booking.agentEmployeeId, label: agentEmployees.find((a) => a.id === booking.agentEmployeeId)!.name } : null}
            onChange={(value: any, actionMeta: any) => {
              const action = actionMeta.action;

              // Create new employee
              if (action === 'create-option') {
                setIsCreatingEmployee(true);
                const name = capitalizeFirstLetters(value.value);
                const abbreviation = name.split(' ').map((i) => i.charAt(0)).join('').toUpperCase(); // Make abbreviation

                createEmployee({ name, abbreviation })
                  .then((res) => {
                    setAgentEmployees([...agentEmployees, res.data]);
                    setBooking({
                      ...booking,
                      agentEmployeeId: res.data.id,
                    });
                    setIsCreatingEmployee(false);
                  }).catch((e) => {
                    setIsCreatingEmployee(false);
                    if (!axios.isCancel(e)) {
                      uiStoreDispatch({
                        type: UIStoreNS.ActionType.AddFlashMessage,
                        payload: { type: 'Error', title: 'Error', text: 'Could not create employee.', timeout: 5000 },
                      });
                    }
                  });
              } else if (action === 'select-option') {
                // Select existing employee
                setBooking({
                  ...booking,
                  agentEmployeeId: parseInt(value.value, 10),
                });
              }
            }}
          />
        </ReactSelectStyled>

        <div className="bc-flex-row">
          <Button
            onClick={() => closeModal()}
            appearance="Secondary"
            tabIndex={-1}
          >Cancel</Button>
          <Button
            disabled={!isFormValid}
            onClick={() => book()}
            isLoading={isCreatingBooking}
          >Book</Button>
        </div>
      </main>
    </Style >
  );
};

export default BookingCreate;
