import styled from 'styled-components';

const ShimmerStyle = styled.div`
  position: relative;
  display: block;
  height: 16px;
  width: 100%;
  overflow: hidden;

  border-radius: 3px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;

  @keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
  }
`;

export default ShimmerStyle;
