const theme = {
  colors: {
    black: '#1C1C1C',
    // Brand
    brand: '#FFAB66',
    // Warning
    warning: '#F7CA28',
    // Error
    danger: '#EF5350',
    // Success
    success: '#70DB7B',
  },
};

export default theme;
