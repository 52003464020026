import axios, { CancelTokenSource } from 'axios';

console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
const baseUrl = process.env.API_URL + '/api/agent';

export default class API {
  async _put(url: string, data: any, cancelToken?: CancelTokenSource) {
    const requestUrl = `${baseUrl}${url}/`;
    try {
      const response = await axios
        .put(requestUrl, data, cancelToken ? { cancelToken: cancelToken.token } : {});
      return response;
    } catch (error) {
      throw error;
    }
  }

  async _get(url: string, cancelToken?: CancelTokenSource) {
    let requestUrl = `${baseUrl}${url}`;
    if (!url.includes('?')) {
      requestUrl += '/';
    }

    try {
      const response = await axios
        .get(requestUrl, cancelToken ? { cancelToken: cancelToken.token } : {});
      return response;
    } catch (error) {
      throw error;
    }
  }

  async _post(url: string, data: any, cancelToken?: CancelTokenSource) {
    const requestUrl = `${baseUrl}${url}/`;

    try {
      const response = await axios
        .post(requestUrl, data, cancelToken ? { cancelToken: cancelToken.token } : {});
      return response;
    } catch (error) {
      throw error;
    }
  }

  async _delete(url: string, cancelToken?: CancelTokenSource) {
    const requestUrl = `${baseUrl}${url}/`;

    try {
      const response = await axios
        .delete(requestUrl, cancelToken ? { cancelToken: cancelToken.token } : {});
      return response;
    } catch (error) {
      throw error;
    }
  }
}
