import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

const Logout: FC<RouteComponentProps> = ({ history }) => {
  useEffect(() => {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      history.replace('/login');
    }).catch(() => {
      // An error happened.
    });
  }, []);

  return (
    <div>Logging you out. One moment..</div>
  );
};

export default Logout;
