import moment, { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { RestaurantResult } from '../../api/dashboard.api';
import theme from '../../styles/theme';
import PriceIndicator from '../PriceIndicator';
import SuggestionItem from '../SuggestionItem';

const slimBp: number = 375; // Breakpoint where restaurant goes horizontally aligned

const Style = styled.div`
  position: relative;
  margin: 8px 16px 16px;
  padding: 8px;

  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(126, 126, 126, 0.15);
  border-radius: 3px;
  user-select: none;
  animation: fadeInRestaurantItem .3s ease-in forwards;

  .ri-favorites {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 4px 4px 2px 4px;

    border-bottom-right-radius: 8px;
    background-color: rgba(0, 0, 0, .05);

    &:hover {
      cursor: pointer;

      > i {
        color: ${theme.colors.black};
        transition: 111ms ease-in;
      }
    }

    &.active {
      > i {
        color: ${theme.colors.danger};
      }
    }

    > i {
      color: #999;
    }
  }

  .ri-img-and-info-container {
    display: flex;

    .ri-img {
      width: 100%;
      height: 128px;
      padding: 16px;

      @media screen and (min-width: ${slimBp}px) {
        width: 96px;
        height: 96px;
        padding: 8px;
        margin-left: 16px;
        max-width: 160px;
        background-position: center center;
        background-size: cover;
      }

      > div {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    .ri-info {
      flex-grow: 1;
      padding: 16px 16px 0px 16px;

      .ri-name-and-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        > div:first-child {
          /* Restaurant name */
          position: relative;
          width: 100%;
          height: 18px;
          overflow: hidden;
          word-break: break-all;

          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 14px;
          letter-spacing: 0.333px;

          &::after {
            /* Gradient if restaurant name is too long */
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            right: 0px;
            width: 32px;
            height: 100%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.7+44,1+69 */
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.7) 44%,rgba(255,255,255,1) 69%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
          }
        }
        > div:last-child {
          /* Price category */
          flex-shrink: 0;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          font-size: 12px;
          letter-spacing: 0.333px;
        }
      }

      .ri-city-and-category {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 11px;
        letter-spacing: 0.333px;
        color: #9B9B9B;
        > div:not(:last-child) {
          margin-bottom: 4px;
        }
        > div:last-child {
          overflow: hidden;
          height: 14px;
        }
      }
    } /* .ri-info ends */
  }

  .ri-suggestions-container {
    width: 100%;

    .ri-suggestions {
      display: flex;
      flex-wrap: wrap;
      padding: 16px;

      > button {
        width: 64px;
        height: 32px;
        margin: 0px 4px 4px 0px;
        font-weight: bold;
        font-size: 10px;
      }
    }
  } /* .ri-info-and-suggestions-container ends */

  @keyframes fadeInRestaurantItem {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

type Props = {
  data: RestaurantResult,
  guests: number,
  dateTime: Moment,
  isFavorite: boolean,
  onFavorited: () => void,
};

const RestaurantItem: FunctionComponent<Props> = ({ data, guests, dateTime, isFavorite, onFavorited }) => {

  return (
    <Style>
      <div
        onClick={onFavorited}
        className={`ri-favorites ${isFavorite ? 'active' : ''}`}
      >
        <i className="material-icons">{isFavorite ? 'favorite' : 'favorite_border'}</i>
      </div>
      <div className="ri-img-and-info-container">
        <div className="ri-img" style={{ backgroundImage: `url(${data.logoUrl})` }} />
        <div className="ri-info">
          <div className="ri-name-and-price">
            <div>{data.restaurantName}</div>
            <PriceIndicator priceCategoryId={data.priceCategoryId} />
          </div>

          <div className="ri-city-and-category">
            <div>{data.city && data.city.name}</div>
            <div>{data.categories.map((c) => c.name).join(', ')}</div>
          </div>
        </div>
      </div>

      <div className="ri-suggestions-container">
        <div className="ri-suggestions">
          {
            data.suggestions.map((s) =>
              <SuggestionItem
                key={`ri-${data.restaurantId}-si-${s.date}`}
                data={s}
                restaurantId={data.restaurantId}
                guests={guests}
                isCurrentlySelected={moment(s.date).format('HH:mm') === dateTime.format('HH:mm')}
              />,
            )}
          {
            data.suggestions.length === 0 && (
              <div>No tables available</div>
            )
          }
        </div>
      </div>
    </Style>
  );
};

export default RestaurantItem;
