import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

export type AgentEmployee = {
  id: number,
  name: string,
  abbreviation: string,
};

const api = new API();

export const getAllEmployees: (cancelToken: CancelTokenSource) => Promise<AxiosResponse<AgentEmployee[]>> =
  (cancelToken: CancelTokenSource) => api._get('/employee', cancelToken);

export const createEmployee: (data: { name: string, abbreviation: string }) => Promise<AxiosResponse<AgentEmployee>> =
  (data) => api._post('/employee', data);
