import { AxiosResponse, CancelTokenSource } from 'axios';
import API from './api';

const api = new API();

export const getFavoriteRestaurants: (cancelToken: CancelTokenSource) => Promise<AxiosResponse<number[]>> =
  (cancelToken: CancelTokenSource) => {
    return api._get('/favorite', cancelToken);
  };

export const setFavoriteRestaurant: (restaurantId: number) => Promise<AxiosResponse<null>> =
  (restaurantId) => {
    return api._post(`/favorite/${restaurantId}`, null);
  };

export const removeFavoriteRestaurant: (restaurantId: number) => Promise<AxiosResponse<boolean>> =
  (restaurantId) => {
    return api._delete(`/favorite/${restaurantId}`);
  };
