import moment from 'moment-timezone';
import * as Moment from 'moment-timezone';
import queryString from 'query-string';
import { useReducer } from 'react';
import { getDefaultSearchDateAndTime } from '../utils/DateUtils';

export namespace SearchCriteriaStoreNS {
  export type State = {
    guests: number,
    dateTime: Moment.Moment,
  };

  export type Store = [{
    guests: number;
    dateTime: Moment.Moment;
  }, React.Dispatch<Action>];

  export enum ActionType {
    Guests,
    Date,
    Time,
  }

  export type Action =
    { type: ActionType.Guests, payload: number } |
    { type: ActionType.Date, payload: string } |
    { type: ActionType.Time, payload: string };
}

export const useSearchCriteriaStore = () => {
  const { guests: qsGuests, dateTime: qsDateTime }: { guests: string, dateTime: string } = queryString.parse(window.location.search) as any;

  const initState: SearchCriteriaStoreNS.State = {
    guests: qsGuests ? parseInt(qsGuests, 10) : 2,
    dateTime: qsDateTime ? moment(qsDateTime, moment.defaultFormatUtc) : getDefaultSearchDateAndTime(moment()),
  };

  const storeReducer = (storeState: SearchCriteriaStoreNS.State, action: SearchCriteriaStoreNS.Action): SearchCriteriaStoreNS.State => {
    switch (action.type) {
      case SearchCriteriaStoreNS.ActionType.Guests:
        return { ...storeState, guests: action.payload };
      case SearchCriteriaStoreNS.ActionType.Date: {
        const { dateTime } = storeState;
        const newDateTime = moment(action.payload, moment.defaultFormatUtc).set({ hour: dateTime.get('hours'), minute: dateTime.get('minutes'), second: 0, millisecond: 0 });
        return { ...storeState, dateTime: newDateTime };
      }
      case SearchCriteriaStoreNS.ActionType.Time: {
        const { dateTime } = storeState;
        const newDateTime = moment(dateTime.format('YYYY-MM-DD') + action.payload, moment.defaultFormat);
        return { ...storeState, dateTime: newDateTime };
      }
      default:
        throw new Error('Could not find state type in search criteria store');
    }
  };

  return useReducer(storeReducer, initState);
};
