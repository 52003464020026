import React, { FC } from 'react';
import styled from 'styled-components';
import InputStyled from './common/Input.styled';

const InputContainerStyled = styled.span`
  position: relative;

  input {
    padding: 0 16px 0px 40px;
  }

  > i {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    color: hsl(0,0%,80%);
  }
`;

type Props = {
  value: string,
  onChange: (value: string) => void;
  placeholder: string,
};

const SearchInput: FC<Props> = ({ value, onChange, placeholder }) => (
  <InputContainerStyled>
    <InputStyled
      type="search"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value || '')}
    />
    <i className="material-icons">search</i>
  </InputContainerStyled>
);

export default SearchInput;
