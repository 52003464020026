import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import FlashMessageConductor from './components/FlashMessageConductor';
import GlobalStyles from './components/GlobalStyles';
import Modal from './components/Modal';
import ModalConductor from './components/ModalConductor';
import Bookings from './pages/Bookings';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Logout from './pages/Logout';

const Layout = () => {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <Switch>
        <Route path="/" component={Dashboard} exact></Route>
        <Route path="/bookings" component={Bookings} exact></Route>
        <Route path="/login" component={Login} exact></Route>
        <Route path="/logout" component={Logout} exact></Route>
      </Switch>
      <Modal>
        <ModalConductor />
      </Modal>
      <FlashMessageConductor />
    </BrowserRouter>
  );
};

export default Layout;
